"use client";
import { useIntercom } from "react-use-intercom";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useRouter } from "next/navigation";
import { useLocale, useTranslations } from "next-intl";
import { twMerge } from "tailwind-merge";

import { Locale } from "@efarmz/efarmz-domain-typescript";

const LanguageSwitcher = () => {
  const t = useTranslations(`dataset`);
  const { hardShutdown } = useIntercom();
  const locale = useLocale();
  const router = useRouter();

  const handleClick = (newLocale: Locale) => () => {
    hardShutdown();
    router.push(`/${newLocale?.toLocaleLowerCase()}`);
  };

  const Items = Object.keys(Locale).map((locale) => (
    <DropdownMenu.Item key={locale.toLowerCase()}>
      <button
        className="group flex w-full items-center rounded-md px-2 py-3 text-sm border-0 hover:bg-gray-100 ring-0 text-left"
        onClick={handleClick(Locale[locale as keyof typeof Locale])}
      >
        <span className="flex-grow text-vert-500 font-semibold">
          {t(`Locale.${locale}`)}
        </span>
      </button>
    </DropdownMenu.Item>
  ));
  /*
  useEffect(() => {
    if (lang) {
      setValue({
        value: lang,
        label: t(`dataset:Locale.${lang.toUpperCase()}`) || undefined,
      });
    }
  }, [t, lang]);
*/

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild className="hidden sm:block">
        <button className="text-xs border rounded p-1">
          {locale.toUpperCase()}
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          //  className="bg-white z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"

          className={twMerge(
            `radix-side-top:animate-slide-up radix-side-bottom:animate-slide-down`,
            `px-1.5 py-1 md:w-60 bg-white z-50 mt-2 w-56 origin-top-right  rounded-md shadow-lg focus:outline-none"`
          )}
          sideOffset={5}
        >
          {Items}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default LanguageSwitcher;
