"use client";
import { Fragment } from "react";
import { HiChevronDown } from "react-icons/hi2";
import { LuCalendarClock } from "react-icons/lu";

import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { format, getWeek, getYear } from "date-fns";
import { useLocale, useTranslations } from "next-intl";
import { twMerge } from "tailwind-merge";

import {
  OrderItemStatus,
  OrderType,
  SubscriptionStatus,
} from "@efarmz/efarmz-domain-typescript";
import Button from "@efarmz/efarmz-react-commons/esm/components/atoms/Button";

import MealOrderSummaryItem from "@/components/molecules/MealOrderSummary/MealOrderSummaryItem";

import useSubscription from "@/hooks/subscriptions/useSubscription";
import useSubscriptionNextDeliveryDate from "@/hooks/subscriptions/useSubscriptionNextDeliveryDate";
import useSubscriptionSelectedMeals from "@/hooks/subscriptions/useSubscriptionSelectedMeals";
import useUpdateSubscriptionItems from "@/hooks/subscriptions/useUpdateSubscriptionItems";

import CartProductList from "../CartProductList";
import CartProductListSkeleton from "../CartProductList/CartProductListSkeleton";

import { Link } from "@/utils/navigation";

const SubscriptionCartList = () => {
  const locale = useLocale();

  const tKey = `components/SubscriptionCartList`;
  const t = useTranslations();

  const subscription = useSubscription();
  const updateSubscriptionItems = useUpdateSubscriptionItems();

  const nextDeliveryDate = useSubscriptionNextDeliveryDate();
  const nextDeliveryWeek = nextDeliveryDate
    ? `${getYear(nextDeliveryDate)}${getWeek(nextDeliveryDate).toLocaleString(
        `en-US`,
        {
          minimumIntegerDigits: 2,
        }
      )}`
    : null;

  const selectedMeals = useSubscriptionSelectedMeals();

  const boxSubscription = subscription?.items?.find((orderItem) =>
    orderItem?.item?.id?.startsWith(`BOX`)
  );

  const productSubscriptions =
    subscription?.items?.filter(
      (orderItem) => !orderItem?.item?.id?.startsWith(`BOX`)
    ) || [];

  const Meals =
    subscription?.mealBox &&
    selectedMeals &&
    nextDeliveryWeek &&
    Object.prototype.hasOwnProperty.call(selectedMeals, nextDeliveryWeek)
      ? selectedMeals[nextDeliveryWeek].map((orderItem) => {
          const meal = orderItem?.item;
          return (
            <li key={meal?.id?.toString()}>
              <MealOrderSummaryItem
                orderItem={orderItem}
                orderType={OrderType.SUBSCRIPTION}
                showIcon={false}
                className="px-0"
                readonly
                imageWrapperProps={{
                  className: `w-20 h-20`,
                }}
              />
            </li>
          );
        })
      : [];

  return subscription ? (
    <>
      <div>
        <div className="text-md text-gray-900 p-4 w-full text-left">
          <div>
            <div className="text-lg font-bold text-vert-700">
              {t(`${tKey}.title`)}
            </div>
            <div className="flex items-center gap-3 w-full mt-2">
              <div
                className={twMerge(
                  `block md:inline-block bg-veggie-500 py-2 px-4 rounded-md text-veggie-50 font-medium text-sm text-center`,
                  subscription?.status !== SubscriptionStatus.ACTIVE &&
                    `bg-orange-500 text-orange-50`
                )}
              >
                {t(`dataset.SubscriptionStatus.${subscription?.status}`)}
              </div>
              {subscription?.status === SubscriptionStatus.ACTIVE && (
                <div className="text-sm text-veggie-500 font-semibold">
                  {t(`${tKey}.subtitle`, {
                    date:
                      subscription.nextDeliveryDate && nextDeliveryDate
                        ? format(nextDeliveryDate, `dd/MM/yyyy`)
                        : null,
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        {!productSubscriptions?.length &&
        !boxSubscription &&
        !subscription?.additionnalItems?.length ? (
          <div className="py-2 px-4">
            <div className="bg-menthe-50 rounded-md py-8">
              <div className="text-center">
                <LuCalendarClock className="mx-auto h-10 w-10 text-vert-500" />

                <h3 className="mt-2 text-sm font-semibold text-gray-900">
                  {t(`${tKey}.empty.title`)}
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  {t(`${tKey}.empty.subtitle`)}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <AccordionPrimitive.Root
            type="single"
            defaultValue="items"
            className={`w-full`}
            collapsible
          >
            {subscription?.mealBox && (
              <>
                <AccordionPrimitive.Item
                  value={`subscription-box`}
                  className="rounded-lg focus:outline-none w-full py-2 px-4"
                >
                  <AccordionPrimitive.Header className="w-full">
                    <AccordionPrimitive.Trigger className="group flex items-center focus:outline-none bg-menthe-50 rounded-lg py-2 px-4 w-full">
                      <HiChevronDown
                        className={twMerge(
                          `mr-4 h-4 w-4 shrink-0 text-gray-700 ease-in-out`,
                          `group-radix-state-open:rotate-180 group-radix-state-open:duration-300`
                        )}
                      />
                      <div className="text-left flex-1">
                        <span className="text-sm font-semibold text-terreau-600 block">
                          {t(`${tKey}.meals.title`)}
                        </span>
                        <span className="text-xs block font-medium text-menthe-800">
                          {boxSubscription?.item?.name}
                        </span>
                      </div>
                      <Link href="/box">
                        <Button className="text-xs font-medium bg-white text-vert-600">
                          {t(`${tKey}.meals.button`)}
                        </Button>
                      </Link>
                    </AccordionPrimitive.Trigger>
                  </AccordionPrimitive.Header>
                  <AccordionPrimitive.Content className="pt-1 w-full rounded-b-lg bg-white pb-3">
                    <ul>{Meals}</ul>
                  </AccordionPrimitive.Content>
                </AccordionPrimitive.Item>
              </>
            )}
            {productSubscriptions?.length ? (
              <AccordionPrimitive.Item
                value={`items`}
                className="rounded-lg focus:outline-none w-full py-2 px-4"
              >
                <AccordionPrimitive.Header className="w-full">
                  <AccordionPrimitive.Trigger className="group flex items-center focus:outline-none bg-menthe-50 rounded-lg py-2 px-4 w-full">
                    <HiChevronDown
                      className={twMerge(
                        `mr-4 h-4 w-4 shrink-0 text-gray-700 ease-in-out `,
                        `group-radix-state-open:rotate-180 group-radix-state-open:duration-300`
                      )}
                    />
                    <div className="text-left">
                      <span className="text-sm font-semibold text-terreau-600 block">
                        {t(`${tKey}.items.title`)}
                      </span>
                      <span className="text-xs block font-medium text-menthe-800">
                        {t(`${tKey}.count-products`, {
                          count: productSubscriptions?.length,
                        })}
                      </span>
                    </div>
                  </AccordionPrimitive.Trigger>
                </AccordionPrimitive.Header>
                <AccordionPrimitive.Content className="pt-1 w-full rounded-b-lg bg-white pb-3">
                  <div className="max-h-60 overflow-y-auto">
                    <CartProductList
                      orderItems={productSubscriptions.map((orderItem) => ({
                        ...orderItem,
                        orderType: OrderType.SUBSCRIPTION,
                        status: OrderItemStatus.AVAILABLE,
                      }))}
                      orderType={OrderType.SUBSCRIPTION}
                      onAddItems={updateSubscriptionItems}
                      rowProps={{
                        className: `px-0`,
                      }}
                    />
                  </div>
                </AccordionPrimitive.Content>
              </AccordionPrimitive.Item>
            ) : null}

            {subscription?.additionnalItems?.length ? (
              <AccordionPrimitive.Item
                value={`additionnal-items`}
                className="rounded-lg focus:outline-none w-full py-2 px-4"
              >
                <AccordionPrimitive.Header className="w-full">
                  <AccordionPrimitive.Trigger className="group flex items-center focus:outline-none bg-menthe-50 rounded-lg py-2 px-4 w-full">
                    <HiChevronDown
                      className={twMerge(
                        `mr-4 h-4 w-4 shrink-0 text-gray-700 ease-in-out `,
                        `group-radix-state-open:rotate-180 group-radix-state-open:duration-300`
                      )}
                    />
                    <div className="text-left">
                      <span className="text-sm font-semibold text-terreau-600 block">
                        {t(`${tKey}.additionnal-items.title`)}
                      </span>
                      <span className="text-xs block font-medium text-menthe-800">
                        {t(`${tKey}.count-products`, {
                          count: subscription?.additionnalItems?.length,
                        })}
                      </span>
                    </div>
                  </AccordionPrimitive.Trigger>
                </AccordionPrimitive.Header>
                <AccordionPrimitive.Content className="pt-1 w-full rounded-b-lg bg-white pb-3">
                  <div className="max-h-60 overflow-y-auto">
                    <CartProductList
                      orderItems={subscription!.additionnalItems!.map(
                        (item) => ({
                          ...item,
                          orderType: OrderType.ONESHOT,
                          status: OrderItemStatus.AVAILABLE,
                        })
                      )}
                      orderType={OrderType.ONESHOT}
                      readonly
                      onAddItems={() => []}
                      rowProps={{
                        className: `px-0`,
                      }}
                    />
                  </div>
                </AccordionPrimitive.Content>
              </AccordionPrimitive.Item>
            ) : null}
          </AccordionPrimitive.Root>
        )}
      </div>
      <div className="p-4 border-t">
        <Link href={`/my/subscription` as any}>
          <Button
            className="w-full"
            startIcon={<LuCalendarClock />}
            color="secondary"
            size="xl"
          >
            {t(`${tKey}.button`)}
          </Button>
        </Link>
      </div>
    </>
  ) : (
    <CartProductListSkeleton />
  );
};

export default SubscriptionCartList;
