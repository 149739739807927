"use client";

import { HiTruck } from "react-icons/hi2";

import Button from "@efarmz/efarmz-react-commons/esm/components/atoms/Button";

const DeliveryWindowFormUpdateDialogSkeleton = () => {
  return (
    <Button
      color="secondary"
      className="flex"
      startIcon={<HiTruck className="w-5 h-5" />}
    >
      <span
        className={`hidden md:block h-2 w-24 bg-vert-500/50 rounded-lg border animate-pulse`}
      />
    </Button>
  );
};

export default DeliveryWindowFormUpdateDialogSkeleton;
