"use client";
import { useEffect, useState } from "react";
import { HiTruck } from "react-icons/hi2";

import { useTranslations } from "next-intl";
import { twMerge } from "tailwind-merge";

import Button from "@efarmz/efarmz-react-commons/esm/components/atoms/Button";
import Dialog from "@efarmz/efarmz-react-commons/esm/components/atoms/Dialog";

import useDeliveryWindow from "@/hooks/useDeliveryWindow";

import DeliveryDialogForm from "./DeliveryDialogForm";

type DeliveryButtonProps = {
  className?: string;
};

const DeliveryButtonV2 = ({ className }: any) => {
  const [isMount, setIsMount] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    setIsMount(true);
  }, []);

  const deliveryWindow = useDeliveryWindow();

  const t = useTranslations();

  return (
    <>
      <Button
        color="secondary"
        className={twMerge(
          `flex md:min-w-[150px] bg-violette-500 hover:bg-violette-700 text-violette-50`,
          className
        )}
        onClick={() => setIsDialogOpen(true)}
        startIcon={<HiTruck className="w-5 h-5" />}
        // aria-label={t(`trigger.aria-label`)}
      >
        <span
          className="hidden md:inline whitespace-nowrap"
          key={deliveryWindow?.value?.address?.zipcode}
        >
          {t(`common.delivery`)}
          {` `}
          {isMount && ` - ${deliveryWindow?.value?.address?.zipcode}`}
        </span>
      </Button>
      <Dialog
        open={isDialogOpen}
        onOpenChange={() => setIsDialogOpen(false)}
        headerProps={{
          className: `py-4`,
        }}
        title="Informations livraison"
        subtitle="Saisissez vos informations de livraison afin de connaître les délais de livraison"
        // eslint-disable-next-line react/no-children-prop
        children={() => {
          return <DeliveryDialogForm />;
        }}
      />
    </>
  );
};

export default DeliveryButtonV2;
