import { twMerge } from "tailwind-merge";

type SearchCommandDialogEmptyProps = {
  className?: string;
};

const SearchCommandDialogEmpty = ({
  className,
}: SearchCommandDialogEmptyProps) => {
  return (
    <div className={twMerge(className)}>
      <h2 className="text-base font-semibold leading-6 text-gray-900">
        Désolé, nous n&apos;avons rien trouvé qui corresponde à votre recherche
      </h2>
      <p className="mt-1 text-sm text-gray-500">
        Veuillez vérifier l&apos;orthographe de votre recherche ou chercher un
        terme plus générique.
      </p>
    </div>
  );
};

export default SearchCommandDialogEmpty;
