"use client";

import { HiOutlineTrash } from "react-icons/hi2";
import { LuGift } from "react-icons/lu";

import { format } from "date-fns";
import { ImageProps } from "next/image";
import { useTranslations } from "next-intl";
import { twMerge } from "tailwind-merge";

import {
  OrderItem,
  OrderItemStatus,
  OrderType,
  Product,
} from "@efarmz/efarmz-domain-typescript";
import Button from "@efarmz/efarmz-react-commons/esm/components/atoms/Button";
import useCurrency from "@efarmz/efarmz-react-commons/esm/hooks/useCurrency";

import Image from "@/components/atoms/Image";

import NumericInput from "@/components/forms/NumericInput";

import useDeliveryWindow from "@/hooks/useDeliveryWindow";

export type CartProductListRowProps = {
  Component?: any;
  orderItem: OrderItem & {
    orderType: OrderType;
  };
  onAddItems: (orderItem: OrderItem[], type: OrderType) => void;
  readonly?: boolean;
  showPrice?: boolean;
  showFree?: boolean;
  orderType?: OrderType;
  className?: string;
  imageProps?: Omit<ImageProps, `src` | `alt`>;
  nameProps?: React.HTMLProps<HTMLHeadingElement>;
  brandProps?: React.HTMLProps<HTMLHeadingElement>;
};

const CartProductListRow = ({
  Component = `li`,
  orderItem,
  onAddItems,
  orderType = OrderType.ONESHOT,
  readonly = false,
  showPrice = true,
  showFree = true,
  className,
  imageProps = {} as ImageProps,
  nameProps = {},
  brandProps = {},
}: CartProductListRowProps) => {
  const currency = useCurrency();
  const t = useTranslations();
  const deliveryWindow = useDeliveryWindow();

  const product: Product = orderItem?.item as Product;

  const { className: imageClassName, ...otherImageProps } = imageProps;
  const { className: nameClassName, ...otherNameProps } = nameProps;
  const { className: brandClassName, ...otherBrandProps } = brandProps;

  let currentStock = 0;

  if (product?.isInfiniteStock) {
    currentStock = 9999;
  } else {
    currentStock =
      product?.stock && deliveryWindow.value.date
        ? product?.stock[format(deliveryWindow.value.date, `yyyyMMdd`)]
        : 0;
  }

  const handleUpdateCart =
    (qty: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
      if (e) {
        e.preventDefault();
      }
      onAddItems([{ item: product, qty }], orderItem?.orderType);
    };

  return (
    <Component
      className={twMerge(`flex py-3 px-6 gap-2`, className)}
      key={product?.id}
    >
      <div className="overflow-hidden rounded-md self-start">
        <div
          className={twMerge(
            `h-20 w-full flex-shrink-0 relative`,
            (readonly ||
              (product?.categories &&
                product?.categories?.length > 0 &&
                product?.categories[0]?.id === `196`)) &&
              `w-24 h-24`
          )}
        >
          <Image
            src={`https://cdn.efarmz.be/art/${product.image}`}
            fill
            alt=""
            sizes="200px"
            className={twMerge(
              `h-full w-full object-cover object-center absolute`,
              imageClassName
            )}
            {...otherImageProps}
          />

          {/*orderItem?.orderType === OrderType.SUBSCRIPTION && (
          <div className="absolute h-6 w-6 bg-famille-700 bottom-0 right-0 rounded-md flex items-center justify-center">
            <LuCalendarClock className="w-4 h-4 text-white" />
          </div>
        )*/}
        </div>
        {!readonly &&
          product?.categories &&
          product?.categories?.length > 0 &&
          product?.categories[0]?.id !== `196` &&
          !orderItem?.item?.id?.startsWith(`BOX`) && (
            <NumericInput
              size="sm"
              className="relative w-24 rounded-none"
              value={orderItem?.qty}
              onClickAdd={
                currentStock - (orderItem?.qty || 0) >= 1
                  ? handleUpdateCart(1)
                  : () => {}
              }
              onClickRemove={handleUpdateCart(-1)}
            />
          )}
      </div>

      <div className="flex flex-1 flex-col">
        <div className="flex w-full">
          <div className="flex-1">
            <span
              className={twMerge(
                `block text-md text-vert-700 font-semibold max-w-md`,
                nameClassName
              )}
              {...otherNameProps}
            >
              {product.name}
            </span>
            {product?.brand?.name && (
              <span
                className={twMerge(
                  `block mt-1 text-sm text-menthe-700 font-medium`,
                  brandClassName
                )}
                {...otherBrandProps}
              >
                {product?.brand?.name || `-`}
              </span>
            )}
            {product?.conditioning ? (
              <span className="block text-xs text-vert-700 mt-1 font-medium">
                {product?.conditioning}
              </span>
            ) : null}

            {orderItem?.status === OrderItemStatus.AVAILABLE ? (
              <>
                {readonly && orderItem?.qty && orderItem?.qty > 1 ? (
                  <span className="mt-3 text-xs">{`${
                    orderItem?.qty
                  }x${currency.format(
                    orderItem?.item?.finalPrice || 0
                  )}`}</span>
                ) : null}
              </>
            ) : (
              <div className="flex flex-row justify-between">
                <p className="text-xs font-medium text-menthe-500 mt-1">
                  {t(`dataset.OrderItemStatus.${orderItem?.status}`)}
                </p>
              </div>
            )}
          </div>
          {(!readonly || showPrice) && (
            <div>
              <div className="flex gap-1 md:gap-2">
                {showFree &&
                  product?.categories &&
                  product?.categories?.length > 0 &&
                  product?.categories[0]?.id === `196` && (
                    <div className="bg-veggie-500 text-veggie-50 p-2 rounded-md text-sm font-medium flex items-center gap-2">
                      <LuGift /> <span>{t(`common.free`)}</span>
                    </div>
                  )}
                <div className="flex flex-col justify-center">
                  <div className="flex flex-col items-end gap-1">
                    {showPrice &&
                      product?.categories &&
                      product?.categories?.length > 0 &&
                      product?.categories[0]?.id !== `196` && (
                        <span className="bg-menthe-100 p-2 rounded-md text-xs md:text-sm text-vert-500 font-semibold">
                          {currency.format(
                            (orderItem?.qty || 1) *
                              (orderItem?.item?.finalPrice || 0)
                          )}
                        </span>
                      )}
                    {product?.deposit &&
                    product?.deposit > 0 &&
                    product?.categories &&
                    product?.categories?.length > 0 &&
                    product?.categories[0]?.id !== `196` ? (
                      <p className="mt-1 text-xs text-menthe-600 font-medium">
                        {t(`common.deposit`)} :{` `}
                        {currency.format(
                          product.deposit * (orderItem.qty || 0)
                        )}
                      </p>
                    ) : null}
                  </div>

                  {orderType !== OrderType.SUBSCRIPTION &&
                  orderItem?.orderType === OrderType.SUBSCRIPTION ? (
                    <div />
                  ) : null}
                </div>

                {!readonly && (
                  <div>
                    <Button
                      className={twMerge(
                        `rounded-md flex items-center justify-center cursor-pointer bg-transparent text-menthe-600 p-2 bg-menthe-50`
                      )}
                      onClick={handleUpdateCart((orderItem?.qty || 0) * -1)}
                    >
                      <HiOutlineTrash />
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Component>
  );
};

export default CartProductListRow;
