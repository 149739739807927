import { parse } from "date-fns";
import { format } from "date-fns";
import { useTranslations } from "next-intl";

import OptionType from "@/types/OptionType";

import {
  Carrier,
  DeliveryType,
  DeliveryWindow,
} from "@efarmz/efarmz-domain-typescript";

export type DeliveryWindowFormData = {
  countryIso2?: OptionType<string> | null;
  zipcode?: string;
  deliveryType: OptionType<string> | null;
  carrier: OptionType<number> | null;
  date: OptionType<string> | null;
  slot?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  phone?: string;
  details?: string;
};

const useDeliveryWindowFormData = (): {
  toFormData: (deliveryWindow: DeliveryWindow) => DeliveryWindowFormData;
  fromFormData: (formData: DeliveryWindowFormData) => DeliveryWindow;
} => {
  const t = useTranslations();

  const toFormData = (
    deliveryWindow: DeliveryWindow
  ): DeliveryWindowFormData => {
    /*

    label: Title,
        subtitle: Subtitle,
        metadata: {
          slot,
          Content: (
            <div>
              {Title}
              {Subtitle}
            </div>
          ),
          lat: parseFloat(marker.lat) || mapDefaultCoordinates.lat,
          lng: parseFloat(marker.lng) || mapDefaultCoordinates.lng,
          address1: marker?.address1,
          postcode: marker?.postcode,
          city: marker?.city,
          delay: marker?.delay,
          */
    return {
      countryIso2: deliveryWindow?.address?.countryIso2
        ? {
            value: deliveryWindow.address.countryIso2,
            label: t(
              `dataset.Country.${deliveryWindow.address.countryIso2.toUpperCase()}`
            ),
          }
        : null,
      date: deliveryWindow?.date
        ? {
            value: format(deliveryWindow.date, `dd/MM/yyyy`),
            label: format(deliveryWindow.date, `dd/MM/yyyy`),
          }
        : null,
      deliveryType: deliveryWindow?.deliveryType
        ? {
            value: deliveryWindow?.deliveryType,
          }
        : null,
      carrier: deliveryWindow?.carrier?.id
        ? {
            value: deliveryWindow.carrier.id,
            metadata: {
              name: deliveryWindow.carrier?.name,
              address1: deliveryWindow.carrier?.address1,
              postcode: deliveryWindow.carrier?.postcode,
              city: deliveryWindow.carrier?.city,
              lat: deliveryWindow.carrier?.position?.lat,
              lng: deliveryWindow.carrier?.position?.lng,
              slot: deliveryWindow?.slot,
              delay: deliveryWindow.carrier?.description,
            },
          }
        : null,
      zipcode: deliveryWindow?.address?.zipcode,
      addressLine1: deliveryWindow?.address?.line1,
      addressLine2: deliveryWindow?.address?.line2,
      city: deliveryWindow?.address?.city,
      phone: deliveryWindow?.address?.phone,
      details: deliveryWindow?.address?.details,
      slot: deliveryWindow?.slot,
    };
  };

  const fromFormData = (formData: DeliveryWindowFormData) => {
    const date = formData?.date?.value
      ? parse(formData.date.value as string, `dd/MM/yyyy`, new Date())
      : undefined;

    let slot;
    let carrier: Carrier | undefined = undefined;
    if (formData?.carrier?.value) {
      carrier = {
        id: formData?.carrier?.value,
      };

      if (formData?.carrier?.metadata?.name) {
        carrier.name = formData.carrier.metadata.name;
      }
      if (formData?.carrier?.metadata?.address1) {
        carrier.address1 = formData.carrier.metadata.address1;
      }
      if (formData?.carrier?.metadata?.postcode) {
        carrier.postcode = formData.carrier.metadata.postcode;
      }
      if (formData?.carrier?.metadata?.city) {
        carrier.city = formData.carrier.metadata.city;
      }
      if (formData?.carrier?.metadata?.delay) {
        carrier.description = formData.carrier.metadata.delay;
      }
      if (formData?.carrier?.metadata?.slot) {
        slot = formData.carrier.metadata.slot;
      }
    }

    const newOptions: DeliveryWindow = {
      date,
      address: {
        line1: formData?.addressLine1,
        line2: formData?.addressLine2,
        zipcode: formData?.zipcode,
        city: formData?.city,
        countryIso2: formData?.countryIso2?.value as string,
        phone: formData?.phone,
        details: formData?.details || ``,
      },
      deliveryType: formData?.deliveryType?.value
        ? DeliveryType[
            formData?.deliveryType?.value as keyof typeof DeliveryType
          ]
        : undefined,
      slot: slot,
      carrier,
    };

    return newOptions;
  };

  return {
    toFormData,
    fromFormData,
  };
};

export default useDeliveryWindowFormData;
