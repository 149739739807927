import { twMerge } from "tailwind-merge";

const ShopFlyoutMenuSkeleton = () => {
  const Categories = Array.from(Array(11).keys()).map((key, i) => {
    const Items = Array.from(Array(i % 2 === 0 ? 8 : 6).keys()).map((key) => {
      return (
        <li key={key} className={`h-4`}>
          <span
            className={twMerge(
              `h-2 block bg-gray-200 rounded-md border animate-pulse`,
              key % 2 === 0 ? `w-36` : `w-20`
            )}
          />
        </li>
      );
    });

    return (
      <div key={key} className="break-inside-avoid-column space-y-2 w-full ">
        <h3 className="text-base h-6 font-bold text-vert-500">
          <span
            className={twMerge(
              `h-3 w-40 block bg-gray-400 rounded-md animate-pulse`,
              i % 2 === 0 ? `w-40` : `w-28`
            )}
          />
        </h3>
        <ul role="list" className="mt-2 space-y-1">
          {Items}
        </ul>
      </div>
    );
  });

  return (
    <div className="relative mx-auto  w-screen">
      <div className="w-5/5  bg-white px-4 py-8 sm:py-3 sm:px-6 lg:px-8 xl:pr-12">
        <div className="relative mt-6">
          <p className="text-base font-semibold leading-7 h-6 text-choux-500">
            <span className="h-3 w-36 block bg-choux-500 rounded-md animate-pulse" />
          </p>

          <div className="relative">
            <h2 className="text-4xl font-bold tracking-tight h-8 text-vert-500 sm:text-2xl">
              <span className="h-4 w-64 block bg-vert-500 rounded-md animate-pulse" />
            </h2>
          </div>
        </div>
        <nav
          className="gap-8 w-full grid sm:grid-cols-5 mt-6 bg-menthe-100/50 rounded-md py-6 px-6"
          aria-labelledby="solutions-heading"
        >
          {Categories}
        </nav>
      </div>
    </div>
  );
};
export default ShopFlyoutMenuSkeleton;
