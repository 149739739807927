import { format } from "date-fns";

import useSubscription from "./useSubscription";
import useSubscriptionOffs from "./useSubscriptionOffs";

const useSubscriptionNextDeliveryDate = (): Date | null => {
  const subscription = useSubscription();
  const offs = useSubscriptionOffs();

  const nextDeliveryDate =
    offs?.length && subscription?.nextDeliveryDates?.length
      ? subscription?.nextDeliveryDates?.find((deliveryDate) => {
          return !(offs || []).includes(format(deliveryDate, `yyyy-MM-dd`));
        })
      : subscription?.nextDeliveryDate;

  return nextDeliveryDate || null;
};

export default useSubscriptionNextDeliveryDate;
