/* eslint-disable jsx-a11y/anchor-is-valid */
//import type { accountPageQuery } from '@/client/relay/queries/__generated__/accountPageQuery.graphql';

"use client";

import React, { useMemo, useState } from "react";
import {
  Configure,
  useHits,
  UseHitsProps,
  useInstantSearch,
  useSearchBox,
  UseSearchBoxProps,
} from "react-instantsearch";

import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { format } from "date-fns";
import { useLocale, useTranslations } from "next-intl";
import { useDebouncedCallback } from "use-debounce";

import { config, OrderableItemType } from "@efarmz/efarmz-domain-typescript";
import TextInput from "@efarmz/efarmz-react-commons/esm/components/forms/TextInput";
import SearchProvider from "@efarmz/efarmz-react-commons/esm/search/SearchProvider";

import { Command } from "@/components/atoms/CommandPalette";

import { search_attributes } from "@/components/molecules/ProductCard";

import useSearchHelper from "@/hooks/helpers/useSearchHelper";
import useDeliveryWindow from "@/hooks/useDeliveryWindow";
import useProductCalendar from "@/hooks/useProductCalendar";

import SearchCommandDialogEmpty from "./SearchCommandDialogEmpty";
import SearchCommandDialogMealRow from "./SearchCommandDialogMealRow";
import SearchCommandDialogProductRow from "./SearchCommandDialogProductRow";
import { HitsSekeleton } from "./SearchCommandDialogSkeleton";

import { useRouter } from "@/utils/navigation";

const Hits = (
  props: UseHitsProps & {
    onSelect: (hit: any) => void;
  }
) => {
  const { onSelect, ...other } = props;
  const { hits } = useHits(other);
  const { status, indexUiState } = useInstantSearch();
  const t = useTranslations(`components/SearchCommandDialog`);

  const [BoxProducts, ShopProducts] = useMemo(() => {
    const BoxProducts: React.ReactNode[] = [];
    const ShopProducts: React.ReactNode[] = [];

    hits.forEach((hit) => {
      const Item = (
        <Command.Item
          key={hit?.objectID}
          value={hit?.objectID}
          onSelect={() => onSelect(hit)}
          className="data-[selected=true]:bg-menthe-50 p-4 rounded-md"
        >
          {hit.type === OrderableItemType.PRODUCT ||
          hit.type === OrderableItemType.BASKET ? (
            <SearchCommandDialogProductRow product={hit as any} />
          ) : null}
          {hit.type === OrderableItemType.MEAL ? (
            <SearchCommandDialogMealRow meal={hit as any} />
          ) : null}
        </Command.Item>
      );
      if (hit?.type === OrderableItemType.MEAL) {
        BoxProducts.push(Item);
      } else {
        ShopProducts.push(Item);
      }
    });
    return [BoxProducts, ShopProducts];
  }, [hits, onSelect]);

  if (status === `stalled`) {
    return (
      <div className="h-96 flex flex-col justify-start items-center bg-white rounded-lg mt-3">
        <div className="rounded-lg py-4 px-4 sticky top-0 bg-vert-500 z-10 flex gap-2 items-center w-full">
          <span
            className={`h-3 w-24 block bg-gray-50 rounded-lg border animate-pulse`}
          />
        </div>
        <div className="pt-2 pb-4 w-full flex flex-col gap-2">
          {HitsSekeleton}
        </div>
      </div>
    );
  }

  return (
    <Command.List
      {...other}
      className="h-96 overflow-y-scroll relative  bg-white rounded-lg mt-3"
    >
      {BoxProducts.length > 0 && (
        <>
          <div className="rounded-lg py-2 px-4 sticky top-0 bg-vert-500 z-10 flex gap-2 items-center">
            <span className="text-xl font-secondary font-medium text-white">
              {t(`meals.title`)}
            </span>
          </div>
          <Command.Group className="pt-2 pb-4">{BoxProducts}</Command.Group>
        </>
      )}
      {ShopProducts.length > 0 && (
        <>
          <div className="rounded-lg py-2 px-4 sticky top-0 bg-vert-500 z-10 flex gap-2 items-center">
            <span className="text-xl font-secondary font-medium text-white">
              {t(`shop.title`)}
            </span>
          </div>
          <Command.Group className="pt-2 pb-4">{ShopProducts}</Command.Group>
        </>
      )}
      {status === `idle` &&
        BoxProducts.length === 0 &&
        ShopProducts.length === 0 && (
          <>
            {indexUiState.query ? (
              <SearchCommandDialogEmpty className="p-4" />
            ) : (
              HitsSekeleton
            )}
          </>
        )}
    </Command.List>
  );
};

const SearchBox = (props: UseSearchBoxProps) => {
  const { query, refine } = useSearchBox(props);
  const [value, setValue] = useState(``);

  const t = useTranslations(`components/SearchCommandDialog`);

  const refineDebounced = useDebouncedCallback(
    refine,
    // delay in ms
    200
  );

  const handleChange = (e: any) => {
    const str = e.target.value;
    setValue(str);
    refineDebounced(str);
  };

  return (
    <div className="relative border-b border-menthe-100 flex items-center !bg-white rounded-md">
      <MagnifyingGlassIcon
        className="pointer-events-none absolute left-4 top-4.5 h-6 w-6 text-menthe-500"
        aria-hidden="true"
      />
      <form>
        <TextInput
          autoFocus
          className="!bg-white h-16 w-full border-0 bg-transparent text-vert-500 placeholder:text-menthe-500 focus:ring-0 sm:text-xl font-semibold"
          placeholder={t(`search`)}
          value={value || ``}
          onChange={handleChange}
        />
      </form>
    </div>
  );
};
type SearchCommandDialogProps = {
  onClose: () => void;
};
const SearchCommandDialog = ({ onClose }: SearchCommandDialogProps) => {
  const t = useTranslations(`components/SearchCommandDialog`);
  const deliveryWindow = useDeliveryWindow();
  const router = useRouter();
  const locale = useLocale();
  const searchHelper = useSearchHelper(locale);

  const { getBoxCurrentPeriod } = useProductCalendar();

  const productFilters = `isOnline:true AND (type:PRODUCT OR type:BASKET) AND (${config.menuRootCategoryIds
    .map((catId) => `categoryId:${catId}`)
    .join(` OR `)})`;

  return (
    <>
      <SearchProvider
        routing={false}
        host="/search"
        apiKey={process.env.NEXT_PUBLIC_SEARCH_API_KEY!}
        index={`orderable-items-${locale}`}
        settings={{
          search_attributes: [`name`, `categories.name`, `brand.name`],
          result_attributes: search_attributes,
          sorting: {
            default: [
              {
                field: `_score`,
                order: `desc`,
              },
              {
                field: `search-score-${locale}.${format(
                  deliveryWindow?.value?.date || new Date(),
                  `yyyyMMdd`
                )}`,
                order: `desc`,
              },
            ],
          },
          facet_attributes: [
            {
              attribute: `tags`,
              field: `tags.keyword`,
              type: `string`,
            },
            {
              attribute: `brand`,
              field: `brand.name.keyword`,
              type: `string`,
            },
            {
              attribute: `categories_level_0`,
              field: `categories_level_0.keyword`,
              type: `string`,
            },
            {
              attribute: `categories_level_1`,
              field: `categories_level_1.keyword`,
              type: `string`,
            },
            {
              attribute: `categories_level_2`,
              field: `categories_level_2.keyword`,
              type: `string`,
            },
            { attribute: `finalPrice`, field: `finalPrice`, type: `numeric` },
          ],
          filter_attributes: [
            {
              attribute: `categoryId`,
              field: `categories.id.keyword`,
              type: `string`,
            },
            { attribute: `type`, field: `type.keyword`, type: `string` },
            { attribute: `isOnline`, field: `isOnline`, type: `string` },
            { attribute: `hasVariant`, field: `hasVariant`, type: `string` },

            {
              attribute: `categories`,
              field: `categories.name.keyword`,
              type: `string`,
            },
            {
              attribute: `availableWeeks`,
              field: `availableWeeks.keyword`,
              type: `string`,
            },
          ],
        }}
      >
        <div className="absolute left-0 right-0 top-0">
          <div className="h-16 md:h-8 w-full bg-menthe-500"></div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
            className=" fill-menthe-500"
          >
            <path d="m0 192 60-10.7c60-10.3 180-32.3 300-32 120-.3 240 21.7 360 53.4 120 32.3 240 74.3 360 74.6 120-.3 240-42.3 300-64l60-21.3V0H0Z" />
          </svg>
        </div>
        <div className="relative">
          <Configure
            filters={`(${productFilters}) OR (type:MEAL AND availableWeeks:${getBoxCurrentPeriod()} AND hasVariant:true)`}
          />

          <Command label="Command Menu" shouldFilter={false}>
            <SearchBox />
            <Hits
              onSelect={(hit: any) => {
                onClose();
                if (hit?.type === OrderableItemType.PRODUCT) {
                  router.push(searchHelper.getShopItemUrl(hit as any) as any);
                }
                if (hit?.type === OrderableItemType.MEAL) {
                  router.push(`/box?mealId=${hit.id}` as any);
                }
              }}
            />
          </Command>
        </div>
      </SearchProvider>
    </>
  );
};

export default SearchCommandDialog;
