"use client";
import { useEffect, useState } from "react";
import { HiTruck } from "react-icons/hi2";

import { useTranslations } from "next-intl";
import { twMerge } from "tailwind-merge";

import Feature from "@/types/Feature";

import Button from "@efarmz/efarmz-react-commons/esm/components/atoms/Button";

import DeliveryWindowFormUpdateDialog from "@/components/organisms/DeliveryWindowFormUpdateDialog";

import useDeliveryWindow from "@/hooks/useDeliveryWindow";
import useFeatureFlag from "@/hooks/useFeatureFlag";

import DeliveryButtonV2 from "./DeliveryButtonV2";

type DeliveryButtonProps = {
  className?: string;
};

const DeliveryButton = ({ children, className }: any) => {
  const tKey = `components/Header`;
  const [isMount, setIsMount] = useState(false);

  useEffect(() => {
    setIsMount(true);
  }, []);
  const t = useTranslations();
  const deliveryWindow = useDeliveryWindow();

  const hasNextDlw = useFeatureFlag(Feature.DLW_NEXT);

  if (hasNextDlw) {
    return <DeliveryButtonV2 />;
  }

  return (
    <>
      <Button
        color="secondary"
        className={twMerge(`flex md:min-w-[150px]`, className)}
        onClick={deliveryWindow?.openForm}
        startIcon={<HiTruck className="w-5 h-5" />}
        aria-label={t(`${tKey}.buttons.delivery.aria-label`)}
        tabIndex={0}
      >
        <span
          className="hidden md:inline whitespace-nowrap"
          key={deliveryWindow?.value?.address?.zipcode}
        >
          {children}
          {isMount && ` - ${deliveryWindow?.value?.address?.zipcode}`}
        </span>
      </Button>
      <DeliveryWindowFormUpdateDialog />
    </>
  );
};

export default DeliveryButton;
