import { useTranslations } from "next-intl";
import { twMerge } from "tailwind-merge";

import { Meal, OrderableItemTag } from "@efarmz/efarmz-domain-typescript";
import Button from "@efarmz/efarmz-react-commons/esm/components/atoms/Button";
import Tag from "@efarmz/efarmz-react-commons/esm/components/atoms/Tag";

import Image from "@/components/atoms/Image";

type SearchCommandDialogMealRowProps = {
  meal: Meal;
  className?: string;
};

const SearchCommandDialogMealRow = ({
  meal,
  className,
}: SearchCommandDialogMealRowProps) => {
  const tKey = `components/SearchCommandDialogMealRow`;
  const t = useTranslations();
  const Tags = meal?.tags?.length
    ? meal.tags
        .filter(
          (tag) =>
            ![
              OrderableItemTag.GLUTEN_FREE,
              OrderableItemTag.LACTOSE_FREE,
              OrderableItemTag.PREMIUM_1_5,
              OrderableItemTag.PREMIUM_75,
              OrderableItemTag.CLASSIC,
            ].includes(tag)
        )
        .map((key) => {
          return (
            <Tag
              key={key}
              tag={OrderableItemTag[key as keyof typeof OrderableItemTag]}
              active
              size="xs"
              label={t(`dataset.Tags.${key}`)}
            />
          );
        })
    : [];

  return (
    <div className={twMerge(className)}>
      <div className="flex gap-4">
        <div className="h-20 w-20 flex-shrink-0 overflow-hidden rounded-md border border-gray-200 relative">
          <Image
            src={`https://cdn.efarmz.be/art/${meal.image}`}
            fill
            alt=""
            sizes="73px"
            className="h-full w-full object-cover object-center"
          />
        </div>

        <div className="flex flex-1 justify-between">
          <div>
            <h3 className="text-sm md:text-md font-semibold text-terreau">
              {meal.name}
            </h3>
            <p className="text-vert-500 text-xs md:text-sm font-medium">
              {meal?.brand?.name}
            </p>

            {Tags?.length > 0 && (
              <div className="hidden justify-center md:inline-flex items-center space-x-1 mt-2">
                {Tags}
              </div>
            )}
          </div>
          <div>
            <Button
              color="secondary"
              variant="contained"
              className="hidden md:block"
            >
              {t(`${tKey}.buttons.details`)}
            </Button>
          </div>
        </div>
      </div>
      <Button
        color="secondary"
        variant="contained"
        className="md:hidden mt-2 w-full"
        size="xl"
      >
        {t(`${tKey}.buttons.details`)}
      </Button>
    </div>
  );
};

export default SearchCommandDialogMealRow;
