"use client";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { useTranslations } from "next-intl";

import DeliveryWindowForm from "@/components/molecules/DeliveryWindowForm";

import useDeliveryWindowFormData, {
  DeliveryWindowFormData,
} from "@/hooks/formData/useDeliveryWindowFormData";
import useDeliveryOptions from "@/hooks/useDeliveryOptions";
import useDeliveryWindow from "@/hooks/useDeliveryWindow";

type DeliveryButtonProps = {
  className?: string;
  v2?: boolean;
};

const DeliveryDialogForm = ({ className, v2 }: any) => {
  const [isMount, setIsMount] = useState(false);

  useEffect(() => {
    setIsMount(true);
  }, []);

  const deliveryWindow = useDeliveryWindow();
  const formData = useDeliveryWindowFormData();
  const t = useTranslations(`components/DeliveryWindowFormUpdateDialog`);

  const { deliveryOptions } = useDeliveryOptions();
  console.log(`deliveryOptions`, deliveryOptions);

  const defaultValues = formData.toFormData(deliveryWindow.value);

  const form = useForm<DeliveryWindowFormData>({
    mode: `onChange`,
    defaultValues,
  });

  return (
    <>
      <DeliveryWindowForm form={form} deliveryOptions={deliveryOptions} />
    </>
  );
};

export default DeliveryDialogForm;
