"use client";
import { useEffect, useState } from "react";

import dynamic from "next/dynamic";
import { twMerge } from "tailwind-merge";

import Button, {
  ButtonProps,
} from "@efarmz/efarmz-react-commons/esm/components/atoms/Button";

import useCart from "@/hooks/useCart";

const CartSidebar = dynamic(
  () => import(`@/components/organisms/CartSidebar`),
  {
    ssr: false,
  }
);

type CartButtonProps = ButtonProps & {
  children: React.ReactNode;
};
const CartButton = ({ children, ...other }: CartButtonProps) => {
  const [isCartSidebarOpen, setisCartSidebarOpen] = useState(false);
  const [isMount, setIsMount] = useState(false);

  const cart = useCart();

  useEffect(() => {
    setIsMount(true);
  }, []);

  return (
    <>
      <Button
        className="relative"
        onClick={() => setisCartSidebarOpen(true)}
        {...other}
      >
        {children}

        {isMount && cart?.count > 0 && (
          <span
            className={twMerge(
              `absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full`
            )}
          >
            {cart?.count}
          </span>
        )}
      </Button>
      <CartSidebar
        open={isCartSidebarOpen}
        onClose={() => setisCartSidebarOpen(false)}
      />
    </>
  );
};

export default CartButton;
