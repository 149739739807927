/* This example requires Tailwind CSS v2.0+ */
"use client";

import { useTranslations } from "next-intl";

import Button from "@efarmz/efarmz-react-commons/esm/components/atoms/Button";

import AccountDropdownMenu from "@/components/molecules/AccountDropdownMenu";

import useAuth from "@/hooks/useAuth";

import HeaderSubscriptionInfo from "../HeaderSubscriptionInfo";

import { Link } from "@/utils/navigation";

const AuthButtonGroup = () => {
  const tKey = `components/Header`;
  const t = useTranslations(tKey);
  const { user } = useAuth();

  return (
    <>
      <>
        {user?.subscription && <HeaderSubscriptionInfo />}
        {user ? (
          <AccountDropdownMenu />
        ) : (
          <>
            <Link href={`/signup` as any}>
              <Button
                size="md"
                color="primary"
                className="bg-rouge-500 hover:bg-rouge-600"
              >
                {t(`buttons.signup`)}
              </Button>
            </Link>
            <Link href={`/signin` as any}>
              <Button
                size="md"
                className="bg-vert-900/50 text-anis-500 hover:bg-vert-900"
              >
                {t(`buttons.signin`)}
              </Button>
            </Link>
          </>
        )}
      </>
    </>
  );
};

export default AuthButtonGroup;
