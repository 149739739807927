"use client";
import { Fragment } from "react";

import Button from "@efarmz/efarmz-react-commons/esm/components/atoms/Button";

import useSubscription from "@/hooks/subscriptions/useSubscription";

import CartProductListSkeleton from "../CartProductList/CartProductListSkeleton";

const SubscriptionCartList = () => {
  const subscription = useSubscription();

  return subscription ? (
    <>
      <div>
        <div className="text-md text-gray-900 p-4 w-full text-left">
          <div className="flex items-center gap-3 w-full">
            <div>
              <div className="text-lg font-bold text-vert-700 h-7">
                <span className="h-3 w-48 block bg-gray-200 rounded-md animate-pulse" />
              </div>
              <div className="text-xs text-menthe-700 font-medium h-4">
                <span className="h-2 w-48 block bg-gray-200 rounded-md animate-pulse" />
              </div>
            </div>
          </div>
        </div>
        <div className="rounded-lg focus:outline-none w-full py-2 px-4 space-y-4">
          <div className="bg-menthe-50 rounded-lg h-12" />
          {` `}
          <div className="bg-menthe-50 rounded-lg h-12" />
        </div>
      </div>
      <div className="p-4 border-t">
        <Button
          className="w-full h-12"
          //startIcon={<LuCalendarClock />}
          color="secondary"
          size="xl"
        >
          <span className="h-2 w-48 block bg-vert-500 rounded-md animate-pulse" />
        </Button>
      </div>
    </>
  ) : (
    <CartProductListSkeleton />
  );
};

export default SubscriptionCartList;
