/* This example requires Tailwind CSS v2.0+ */
"use client";
import { useState } from "react";
import { HiXMark } from "react-icons/hi2";

import "swiper/css";

import { useLocale } from "next-intl";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import Container from "@efarmz/efarmz-react-commons/esm/components/atoms/Container";

import useSubscription from "@/hooks/subscriptions/useSubscription";

const HeaderBanner = () => {
  const locale = useLocale();
  const subscription = useSubscription();
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
  };

  if (!isOpen || !subscription || !subscription?.mealBox) {
    return null;
  }

  const slides = [
    locale === `fr`
      ? {
          title: `🔥 Du neuf dans votre espace abonné`,
          content: `vos plats sélectionnés sont automatiquement enregistrés ! Plus d'info ici`,
        }
      : {
          title: `🔥 Iets nieuws in uw abonneegedeelte`,
          content: `uw geselecteerde gerechten worden automatisch opgeslagen! Meer informatie hier`,
        },
  ];

  const Slides = slides.map((slide, i) => {
    return (
      <SwiperSlide
        key={i.toString()}
        className="text-sm text-white !flex !items-center !whitespace-nowrap !w-full !flex-nowrap"
      >
        <a
          href="https://youtube.com/shorts/pcVkkcfATjw?feature=share"
          target="_blank"
        >
          <strong className="font-semibold mr-3">{slide.title}</strong>
          {slide.content}
          <span aria-hidden="true" className="ml-4">
            &rarr;
          </span>
        </a>
      </SwiperSlide>
    );
  });
  return (
    <Container className="bg-vert-900 py-2 md:py-3">
      <div className="flex items-center justify-between gap-x-6">
        <div className="flex-1 !overflow-x-auto">
          <Swiper
            direction="vertical"
            className="h-8 !overflow-x-visible"
            autoplay
            modules={[Autoplay, Pagination]}
            wrapperClass="!overflow-x-visible !w-full"
          >
            {Slides}
          </Swiper>
        </div>
        <button
          type="button"
          className="-m-3 flex-none p-3 focus-visible:outline-offset-[-4px]"
          onClick={handleClose}
        >
          <span className="sr-only">Dismiss</span>
          <HiXMark className="h-5 w-5 text-white" aria-hidden="true" />
        </button>
      </div>
    </Container>
  );
};

export default HeaderBanner;
