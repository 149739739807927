import { twMerge } from "tailwind-merge";

type CartProductListSkeletonProps = {
  length?: number;
};

const CartProductListSkeleton = ({
  length = 10,
}: CartProductListSkeletonProps) => {
  const Items = Array.from(Array(length).keys()).map((key) => {
    return (
      <li className={twMerge(`flex py-3 px-4`)} key={key}>
        <div className="h-16 w-16 flex-shrink-0 overflow-hidden rounded-md border border-gray-200 relative bg-gray-100" />

        <div className="ml-4 flex flex-1 flex-col">
          <div className="flex w-full text-sm font-medium text-black">
            <div className="flex-1">
              <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-4" />
              <div className="mt-1 h-2 bg-gray-200 rounded-full max-w-[360px] mb-2.5" />
            </div>
          </div>
        </div>
      </li>
    );
  });

  return <ul>{Items}</ul>;
};

export default CartProductListSkeleton;
