"use client";

import { OrderItem, OrderType } from "@efarmz/efarmz-domain-typescript";

import CartProductListRow from "./CartProductListRow";

type CartProductListProps = {
  orderItems: (OrderItem & {
    orderType: OrderType;
  })[];
  onAddItems: (orderItem: OrderItem[], type: OrderType) => void;
  orderType?: OrderType;
  className?: string;
  readonly?: boolean;
  filter?: (orderItem: OrderItem) => boolean;
  rowProps?: {
    className?: string;
  };
};

const CartProductList = ({
  orderItems,
  onAddItems,
  orderType = OrderType.ONESHOT,
  readonly = false,
  filter = () => true,
  className,
  rowProps = {},
}: CartProductListProps) => {
  const Items = orderItems?.filter(filter).map((orderItem) => {
    return (
      <CartProductListRow
        key={orderItem?.item?.id}
        orderItem={orderItem}
        onAddItems={onAddItems}
        orderType={orderType}
        readonly={readonly}
        {...rowProps}
      />
    );
  });

  return <ul className={className}>{Items}</ul>;
};

export default CartProductList;
