/* eslint-disable jsx-a11y/anchor-is-valid */
//import type { accountPageQuery } from '@/client/relay/queries/__generated__/accountPageQuery.graphql';

"use client";

import { useForm } from "react-hook-form";

import dynamic from "next/dynamic";
import { useTranslations } from "next-intl";

import Dialog from "@efarmz/efarmz-react-commons/esm/components/atoms/Dialog";

import { DeliveryWindowFormSkeleton } from "@/components/molecules/DeliveryWindowForm";

import useDeliveryWindowFormData, {
  DeliveryWindowFormData,
} from "@/hooks/formData/useDeliveryWindowFormData";
import useDeliveryOptions from "@/hooks/useDeliveryOptions";
import useDeliveryWindow from "@/hooks/useDeliveryWindow";

type DeliveryWindowFormUpdateDialogProps = {
  className?: string;
};

//Todo Skeleton
const DeliveryWindowForm = dynamic(
  () => import(`@/components/molecules/DeliveryWindowForm`),
  {
    ssr: false,
    loading: () => <DeliveryWindowFormSkeleton />,
  }
);

const DeliveryWindowFormUpdateDialog = ({
  className,
}: DeliveryWindowFormUpdateDialogProps) => {
  const deliveryWindow = useDeliveryWindow();
  const formData = useDeliveryWindowFormData();
  const t = useTranslations(`components/DeliveryWindowFormUpdateDialog`);

  const { deliveryOptions } = useDeliveryOptions();

  const defaultValues = formData.toFormData(deliveryWindow.value);

  const form = useForm<DeliveryWindowFormData>({
    mode: `onChange`,
    defaultValues,
  });

  const handleSubmit = () => {
    form.handleSubmit((data) => {
      deliveryWindow.setValue(formData.fromFormData(data), deliveryOptions);
      deliveryWindow.closeForm();
    })();
  };

  return (
    <>
      <Dialog
        //title={t(`title`)}
        className="max-w-2xl sm:my-4 overflow-visible rounded-none md:rounded-lg min-h-screen md:min-h-0 md:h-auto"
        wrapperProps={{
          className: `p-0 md:p-6`,
        }}
        contentProps={{
          className: `h-full md:h-auto`,
        }}
        open={deliveryWindow.isFormOpen}
        /*
        onOpenChange={(open) =>
          open ? deliveryWindow.openForm() : deliveryWindow.closeForm()
        }*/
        closeProps={{
          className: `hidden`,
        }}
        actions={() => [
          {
            children: t(`actions.1`),
            variant: `contained`,
            color: `primary`,
            size: `lg`,
            onClick: handleSubmit,
          },
          {
            children: t(`actions.0`),
            variant: `contained`,
            size: `lg`,
            onClick: deliveryWindow.closeForm,
          },
        ]}
      >
        {() => (
          <>
            <div className="bg-menthe-50 p-4 rounded-lg prose text-vert-700 mt-4 mb-6  w-full">
              <span className="text-xs">{t(`infos.hint`)}</span>
              <ul className="text-xs">
                <li>
                  {t.rich(`infos.list.0`, {
                    bold: (chunks) => (
                      <strong className="font-semibold text-vert-900">
                        {chunks}
                      </strong>
                    ),
                  })}
                </li>
                <li>
                  {t.rich(`infos.list.1`, {
                    bold: (chunks) => (
                      <strong className="font-semibold text-vert-900">
                        {chunks}
                      </strong>
                    ),
                  })}
                </li>
              </ul>
            </div>
            <DeliveryWindowForm form={form} deliveryOptions={deliveryOptions} />
          </>
        )}
      </Dialog>
    </>
  );
};

export default DeliveryWindowFormUpdateDialog;
