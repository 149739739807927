"use client";

import { Configure } from "react-instantsearch";

import Image from "next/image";
import { useLocale, useTranslations } from "next-intl";

import { config, OrderableItemTag } from "@efarmz/efarmz-domain-typescript";
import SearchProvider from "@efarmz/efarmz-react-commons/esm/search/SearchProvider";

import useSearchHelper from "@/hooks/helpers/useSearchHelper";
import useShopHierarchicalmenu from "@/hooks/useShopHierarchicalmenu";

import { NavigationLink } from "../HeaderNavigation";
import ShopFlyoutMenuSkeleton from "./ShopFlyoutMenuSkeleton";
import ShopFlyoutMenuTags from "./ShopFlyoutMenuTags";

import SparkleImg from "@/images/sketch-sparkle.svg";
import { Link } from "@/utils/navigation";

const ShopFlyoutMenu = () => {
  const locale = useLocale();
  const t = useTranslations();
  const searchHelper = useSearchHelper(locale);

  const { data: categories, isLoading } = useShopHierarchicalmenu();

  const Categories = categories.map((category, i) => {
    const Items = category.children
      ? category.children.map((item, i) => {
          return (
            <li key={i.toString()} className="flow-root">
              <NavigationLink asChild>
                <Link
                  href={
                    searchHelper.getUrlFromCategory([category, item]) as any
                  }
                  className="text-sm font-medium text-terreau transition duration-150 ease-in-out hover:text-choux-500"
                >
                  {item.name}
                </Link>
              </NavigationLink>
            </li>
          );
        })
      : [];

    /**
     * Display BBQ tag as a category
     */
    if (category.id === `4`) {
      Items.push(
        <li key={i.toString()} className="flow-root">
          <NavigationLink asChild>
            <Link
              href={searchHelper.getTagUrl(OrderableItemTag.BBQ) as any}
              className="text-sm font-medium text-terreau transition duration-150 ease-in-out hover:text-choux-500"
            >
              {OrderableItemTag.BBQ as string}
            </Link>
          </NavigationLink>
        </li>
      );
    }

    return (
      <div key={i.toString()} className="break-inside-avoid-column">
        <h3 className="text-base font-bold text-vert-500">
          <NavigationLink asChild>
            <Link href={searchHelper.getUrlFromCategory([category]) as any}>
              {category.name}
            </Link>
          </NavigationLink>
        </h3>
        <ul role="list" className="mt-2 space-y-1">
          {Items}
        </ul>
      </div>
    );
  });

  Categories.push(
    <ul role="list" className="mt-2 space-y-1">
      <li className="flow-root">
        <NavigationLink asChild>
          <Link
            href={searchHelper.getTagUrl(OrderableItemTag.PROMO) as any}
            className="text-base font-bold text-red-500 transition duration-150 ease-in-out hover:text-red-700"
          >
            {t(`dataset.Tags.${OrderableItemTag.PROMO as string}`)}
          </Link>
        </NavigationLink>
      </li>
      <li className="flow-root">
        <NavigationLink asChild>
          <Link
            href={searchHelper.getTagUrl(OrderableItemTag.ZERO_WASTE) as any}
            className="text-base font-bold text-red-500 transition duration-150 ease-in-out hover:text-red-700"
          >
            {t(`dataset.Tags.${OrderableItemTag.ZERO_WASTE as string}`)}
          </Link>
        </NavigationLink>
      </li>
    </ul>
  );

  const filters = `isOnline:true AND (type:PRODUCT OR type:BASKET) AND (${config.menuRootCategoryIds
    .map((catId) => `categoryId:${catId}`)
    .join(` OR `)})`;
  if (isLoading) {
    return <ShopFlyoutMenuSkeleton />;
  }
  return (
    <div>
      {/*
        <div className="absolute inset-0 flex" aria-hidden="true">
        <div className="w-3/5 bg-white" />
        <div className="w-2/5 bg-[#F1F6F6]" />
      </div>
  */}

      <div className="relative mx-auto flex">
        <div className="w-5/5  bg-white px-4 py-8 sm:py-3 sm:px-6 lg:px-8 xl:pr-12">
          <div className="flex">
            <div className="relative inline-block mt-6">
              <p className="text-base font-semibold leading-7 text-choux-500">
                {t(`components/ShopFlyoutMenu.subtitle`)}
              </p>
              <div className="relative inline-block">
                <h2 className="text-4xl font-bold tracking-tight text-vert-500 sm:text-2xl">
                  {t(`components/ShopFlyoutMenu.title`)}
                </h2>
                <Image
                  src={SparkleImg}
                  alt="sparkle image effect"
                  className="max-w-[28px] absolute -top-3 -right-8"
                />
              </div>
            </div>
          </div>
          <SearchProvider
            host="/search"
            apiKey={process.env.NEXT_PUBLIC_SEARCH_API_KEY!}
            index={`orderable-items-${locale}`}
            routing
            settings={{
              search_attributes: [`name`],

              facet_attributes: [
                {
                  attribute: `tags`,
                  field: `tags.keyword`,
                  type: `string`,
                },
                {
                  attribute: `origin`,
                  field: `origin.keyword`,
                  type: `string`,
                },
                {
                  attribute: `brand`,
                  field: `brand.name.keyword`,
                  type: `string`,
                },
                {
                  attribute: `categories_level_0`,
                  field: `categories_level_0.keyword`,
                  type: `string`,
                },
                {
                  attribute: `categories_level_1`,
                  field: `categories_level_1.keyword`,
                  type: `string`,
                },
                {
                  attribute: `categories_level_2`,
                  field: `categories_level_2.keyword`,
                  type: `string`,
                },
              ],
              //AND stock:[1 TO *]
              filter_attributes: [
                {
                  attribute: `categoryId`,
                  field: `categories.id.keyword`,
                  type: `string`,
                },
                { attribute: `tags`, field: `tags.keyword`, type: `string` },
                { attribute: `type`, field: `type.keyword`, type: `string` },
                { attribute: `isOnline`, field: `isOnline`, type: `string` },
                {
                  attribute: `price`,
                  field: `finalPrice`,
                  type: `numeric`,
                },

                {
                  attribute: `categories`,
                  field: `categories.name.keyword`,
                  type: `string`,
                },
              ],
              /*
      search_attributes: [`name`, `email`],
      result_attributes: [`id`, `name`, `email`],
      highlight_attributes: [`name`],
      */
            }}
          >
            <Configure filters={filters} hitsPerPage={30} />

            <ShopFlyoutMenuTags className="mt-4" />
          </SearchProvider>
          <nav
            className="gap-8 sm:columns-5 sm:gap-x-8 mt-3 space-y-8 bg-menthe-100/50 rounded-md py-6 px-6"
            aria-labelledby="solutions-heading"
          >
            {Categories}
          </nav>
        </div>
      </div>
    </div>
  );
};
export default ShopFlyoutMenu;
