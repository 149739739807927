/* This example requires Tailwind CSS v2.0+ */
"use client";
import { useEffect } from "react";

import dynamic from "next/dynamic";
import { useTranslations } from "next-intl";

import useDialog from "@efarmz/efarmz-react-commons/esm/hooks/useDialog";

import { SearchCommandDialogSkeleton } from "@/components/organisms/SearchCommandDialog";

const SearchCommandDialog = dynamic(
  () => import(`@/components/organisms/SearchCommandDialog`),
  {
    ssr: false,
    loading: () => <SearchCommandDialogSkeleton />,
  }
);

type SearchButtonProps = {
  children: React.ReactNode;
};

const SearchButton = ({ children }: SearchButtonProps) => {
  const tKey = `components/Header`;
  const t = useTranslations(tKey);

  const [SearchDialog, searchDialogHandler] = useDialog({
    contentProps: {
      className: `sm:px-3 relative`,
    },
    closeProps: {
      className: `bg-menthe-100 top-8 right-6`,
    },
    children: ({ closeDialog }) => {
      return <SearchCommandDialog onClose={closeDialog} />;
    },
  });

  useEffect(() => {
    const down = (e: any) => {
      if (e.key === `k` && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        searchDialogHandler.show();
      }
      if (e.keyCode == 32 && e.target == document.body) {
        e.preventDefault();
      }
    };

    document.addEventListener(`keydown`, down);
    return () => document.removeEventListener(`keydown`, down);
  }, []);

  return (
    <>
      {SearchDialog}

      <button onClick={() => searchDialogHandler.show()}>{children}</button>
    </>
  );
};

export default SearchButton;
