/* eslint-disable jsx-a11y/anchor-is-valid */
//import type { accountPageQuery } from '@/client/relay/queries/__generated__/accountPageQuery.graphql';

import React from "react";

import { MagnifyingGlassIcon } from "@radix-ui/react-icons";

export const HitsSekeleton = Array.from(Array(10).keys()).map((key) => {
  return (
    <div key={key} className="flex w-full gap-4 min-h-[90px] p-4">
      <div className="h-20 w-20 bg-gray-200 animate-pulse rounded-md" />
      <div className="flex-1">
        <span
          className={`h-3 w-36 block bg-gray-200 rounded-lg border animate-pulse`}
        />
        <span
          className={`h-2 w-20 block bg-gray-200 rounded-lg border animate-pulse mt-2`}
        />
        <span
          className={`h-2 w-14 block bg-gray-200 rounded-lg border animate-pulse mt-2`}
        />
      </div>
      <div>
        <div className="h-8 w-20 bg-gray-200 animate-pulse rounded-md" />
      </div>
    </div>
  );
});

const SearchCommandDialogSkeleton = () => {
  return (
    <>
      <div className="absolute left-0 right-0 top-0">
        <div className="h-16 md:h-8 w-full bg-menthe-500"></div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          className=" fill-menthe-500"
        >
          <path d="m0 192 60-10.7c60-10.3 180-32.3 300-32 120-.3 240 21.7 360 53.4 120 32.3 240 74.3 360 74.6 120-.3 240-42.3 300-64l60-21.3V0H0Z" />
        </svg>
      </div>
      <div className="relative">
        <div className="relative border-b border-menthe-100 flex items-center !bg-white rounded-md overflow-hidden">
          <MagnifyingGlassIcon
            className="pointer-events-none absolute left-4 top-4.5 h-6 w-6 text-menthe-500"
            aria-hidden="true"
          />

          <div className="!bg-white h-16 w-full border-0 bg-transparent text-vert-500 placeholder:text-menthe-500 focus:ring-0 sm:text-xl font-semibold" />
        </div>
        <div className="h-96 flex flex-col justify-start items-center bg-white rounded-lg mt-3">
          <div className="rounded-lg py-4 px-4 sticky top-0 bg-vert-500 z-10 flex gap-2 items-center w-full">
            <span
              className={`h-3 w-24 block bg-gray-50 rounded-lg border animate-pulse`}
            />
          </div>
          <div className="pt-2 pb-4 w-full flex flex-col gap-2">
            {Array.from(Array(10).keys()).map((key) => {
              return (
                <div key={key} className="flex w-full gap-4 min-h-[90px] p-4">
                  <div className="h-20 w-20 bg-gray-200 animate-pulse rounded-md" />
                  <div className="flex-1">
                    <span
                      className={`h-3 w-36 block bg-gray-200 rounded-lg border animate-pulse`}
                    />
                    <span
                      className={`h-2 w-20 block bg-gray-200 rounded-lg border animate-pulse mt-2`}
                    />
                    <span
                      className={`h-2 w-14 block bg-gray-200 rounded-lg border animate-pulse mt-2`}
                    />
                  </div>
                  <div>
                    <div className="h-8 w-20 bg-gray-200 animate-pulse rounded-md" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchCommandDialogSkeleton;
