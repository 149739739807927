import { useLocale } from "next-intl";
import useSWR from "swr";

import { Category } from "@efarmz/efarmz-domain-typescript";

import SWRFetcher from "@/utils/SWRFetcher";

const useShopHierarchicalmenu = (): {
  data: Category[];
  isLoading: boolean;
} => {
  const locale = useLocale();

  const { data, error, isLoading } = useSWR(
    {
      url: `${process.env.NEXT_PUBLIC_EFARMZ_URL}/search/categories-${locale}/_search?size=50`,
      opts: {
        headers: {
          Authorization: `ApiKey ${process.env.NEXT_PUBLIC_SEARCH_API_KEY!}`,
        },
      },
    },
    SWRFetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const categories = data?.hits?.hits
    ? data.hits.hits.map((hit: any) => hit._source)
    : [];

  return {
    data: categories,
    isLoading,
  };
};

export default useShopHierarchicalmenu;
