import {
  HiOutlineCurrencyEuro,
  HiOutlineFolderMinus,
  HiOutlineShoppingCart,
  HiOutlineStar,
  HiOutlineUsers,
} from "react-icons/hi2";
import { LuCalendarClock } from "react-icons/lu";
import { PiCookingPot } from "react-icons/pi";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { signOut } from "next-auth/react";
import { useTranslations } from "next-intl";
import { twMerge } from "tailwind-merge";

import Button from "@efarmz/efarmz-react-commons/esm/components/atoms/Button";

import useSubscription from "@/hooks/subscriptions/useSubscription";
import useAuth from "@/hooks/useAuth";

import { Link, usePathname } from "@/utils/navigation";

type AuthDropdownMenuProps = {
  className?: string;
};

const AccountDropdownMenu = ({ className }: AuthDropdownMenuProps) => {
  const tKey = `components/MyMenu`;

  const t = useTranslations();

  const { user } = useAuth();

  const pathname = usePathname();

  const subscription = useSubscription();

  const navigation = [
    {
      name: t(`${tKey}.links.account`),
      href: `/my/account`,
      icon: HiOutlineFolderMinus,
      current: true,
    },
    {
      name: t(`${tKey}.links.subscription`),
      href: `/my/subscription`,
      icon: LuCalendarClock,
      current: false,
    },
    {
      name: t(`${tKey}.links.recipes`),
      href: `/my/recipes`,
      icon: PiCookingPot,
      current: true,
    },
    {
      name: t(`${tKey}.links.orders`),
      href: `/my/orders`,
      icon: HiOutlineShoppingCart,
    },
    {
      name: t(`${tKey}.links.refunds`),
      href: `/my/refunds`,
      icon: HiOutlineCurrencyEuro,
    },
    {
      name: t(`${tKey}.links.feedbacks`),
      href: `/my/feedbacks`,
      icon: HiOutlineStar,
    },
    {
      name: t(`${tKey}.links.sponsorship`),
      href: `/my/sponsorship`,
      icon: HiOutlineUsers,
    },
  ].filter((link) => {
    if (!subscription) {
      if (link.href === `/my/subscription`) {
        return false;
      }
    }
    return true;
  });

  const Items = navigation.map((item) => {
    return (
      <DropdownMenu.Item
        key={item.name}
        className="ring-vert-500 focus:border-menthe-100 focus:outline-menthe-100"
        asChild
      >
        <Link
          href={item.href as any}
          className="group flex w-full items-center rounded-md px-2 py-3 text-sm border-0 hover:bg-menthe-50 focus:border-vert-500"
        >
          <item.icon className="mr-2 h-5 w-5 text-choux-500" />
          <span className="flex-grow text-vert-500 font-medium">
            {item?.name}
          </span>
        </Link>
      </DropdownMenu.Item>
    );
  });
  return (
    <DropdownMenu.Root key={pathname}>
      <DropdownMenu.Trigger asChild>
        <Button
          size="md"
          color="primary"
          className="bg-vert-800 text-anis-500 hover:bg-vert-900 truncate max-w-36 ring-choux-500"
        >
          {user?.name}
        </Button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Content
        className={twMerge(
          `radix-side-top:animate-slide-up radix-side-bottom:animate-slide-down`,
          `px-1.5 py-1 md:w-60 bg-white z-50 mt-2 w-56 origin-top-right  rounded-md shadow-lg focus:outline-none`
        )}
        sideOffset={5}
      >
        {Items}

        <DropdownMenu.Separator className="DropdownMenuSeparator" />
        <DropdownMenu.Item className="group flex w-full items-center rounded-md px-2 py-2 text-sm focus:border-menthe-100 focus:outline-menthe-100">
          <Button
            onClick={() => signOut()}
            className="w-full"
            variant="contained"
            color="secondary"
          >
            {t(`${tKey}.links.logout`)}
          </Button>
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default AccountDropdownMenu;
