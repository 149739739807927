import { useTranslations } from "next-intl";
import { twMerge } from "tailwind-merge";

import { OrderableItemTag, Product } from "@efarmz/efarmz-domain-typescript";
import Tag from "@efarmz/efarmz-react-commons/esm/components/atoms/Tag";
import useCurrency from "@efarmz/efarmz-react-commons/esm/hooks/useCurrency";

import Image from "@/components/atoms/Image";

import ProductActionButtons from "@/components/molecules/ProductActionButtons";

type SearchCommandDialogProductRowProps = {
  product: Product;
  className?: string;
};

const SearchCommandDialogProductRow = ({
  product,
  className,
}: SearchCommandDialogProductRowProps) => {
  const t = useTranslations();
  const currency = useCurrency();
  const Tags = product?.tags?.length
    ? product.tags
        .filter(
          (tag) =>
            ![
              OrderableItemTag.PREMIUM_1_5,
              OrderableItemTag.PREMIUM_75,
              OrderableItemTag.CLASSIC,
              OrderableItemTag.PROMO,
            ].includes(tag)
        )
        .map((key) => {
          return (
            <Tag
              key={key}
              tag={OrderableItemTag[key as keyof typeof OrderableItemTag]}
              active
              size="xs"
              label={t(`dataset.Tags.${key}`)}
            />
          );
        })
    : [];

  const showDiscount =
    product?.tags?.includes(OrderableItemTag.PROMO) ||
    product?.tags?.includes(OrderableItemTag.QUICKSALE) ||
    product?.tags?.includes(OrderableItemTag.FLASH_HENRY) ||
    product?.tags?.includes(OrderableItemTag.ZERO_WASTE);

  return (
    <div className={twMerge(className)}>
      <div className="flex">
        <div className="h-20 w-20 flex-shrink-0 overflow-hidden rounded-md border border-gray-200 relative bg-menthe-300">
          <Image
            src={`https://cdn.efarmz.be/art/${product.image}`}
            fill
            alt=""
            sizes="73px"
            className="h-full w-full object-cover object-center"
          />
          <div className="absolute bottom-0 left-0 p-1">
            {showDiscount && (
              <div>
                {product?.discount ? (
                  <Tag
                    tag={OrderableItemTag.PROMO}
                    label={`-${product?.discount?.rate}%`}
                    size="xs"
                    active
                    showIcon={false}
                    className="rounded-md"
                  />
                ) : (
                  <Tag
                    tag={OrderableItemTag.PROMO}
                    label={t(`dataset.Tags.${OrderableItemTag.PROMO}`)}
                    size="xs"
                    active
                    showIcon={false}
                    className="rounded-md"
                  />
                )}
              </div>
            )}
          </div>
        </div>

        <div className="ml-4 flex flex-1 justify-between">
          <div>
            <h3 className="text-sm md:text-md font-semibold vert-dark">
              {product.name}
            </h3>
            <p className="text-vert-500 text-sm font-medium">
              {product?.brand?.name}
            </p>
            <div className="flex gap-2 items-center mt-1">
              <span className="text-xs md:hidden bg-menthe-100 p-2 rounded-md md:text-sm text-terreau font-semibold">
                {currency.format(product.finalPrice || 0)}
              </span>
              <span className="text-xs md:text-sm block text-gray-600">
                {product?.conditioning}
              </span>
            </div>

            {Tags?.length > 0 && (
              <div className="hidden md:flex ustify-center items-center space-x-1 mt-2">
                {Tags}
              </div>
            )}
          </div>
          <div className="hidden md:block">
            <div className="flex flex-col items-end pb-4">
              <div className="flex">
                <span
                  className={twMerge(
                    `bg-menthe-100 p-2 rounded-md text-sm text-vert-500 font-semibold`,
                    showDiscount && product?.discount && `rounded-r-none`
                  )}
                >
                  {currency.format(product.finalPrice || 0)}
                </span>
                {showDiscount && product?.discount && (
                  <span className="bg-rouge-100 p-2 rounded-r-md text-sm text-rouge-500 font-semibold line-through">
                    {currency.format(product?.discount?.basePrice || 0)}
                  </span>
                )}
              </div>
            </div>
            <ProductActionButtons product={product} size="md" />
          </div>
        </div>
      </div>
      <ProductActionButtons
        product={product}
        size="xl"
        className="md:hidden mt-2"
      />
    </div>
  );
};

export default SearchCommandDialogProductRow;
