import { useInstantSearch, useMenu } from "react-instantsearch";

import { useLocale, useTranslations } from "next-intl";
import { twMerge } from "tailwind-merge";

import { OrderableItemTag } from "@efarmz/efarmz-domain-typescript";
import Tag from "@efarmz/efarmz-react-commons/esm/components/atoms/Tag";

import useSearchHelper from "@/hooks/helpers/useSearchHelper";

import { NavigationLink } from "../HeaderNavigation";

import { Link } from "@/utils/navigation";

type MealTagsProps = {
  className?: string;
};

const Loading = () =>
  Array.from(Array(8).keys()).map((key) => {
    return (
      <div
        key={key}
        className="h-7 w-20 bg-menthe-100 rounded-md animate-pulse"
      />
    );
  });

const excludedItems = [
  OrderableItemTag.PREMIUM_75,
  OrderableItemTag.PREMIUM_1_5,
  OrderableItemTag.CLASSIC,
  OrderableItemTag.VEGGIE,
  OrderableItemTag.VALENTIN,
];

const ShopFlyoutMenuTags = ({ className }: MealTagsProps) => {
  const locale = useLocale();
  const { items } = useMenu({
    attribute: `tags`,
    sortBy: [`name:asc`],
    limit: 100,
  });
  const t = useTranslations();
  const { getTagUrl } = useSearchHelper(locale);

  const { status } = useInstantSearch();

  const Items = items
    .filter(
      (item) =>
        !excludedItems.includes(
          OrderableItemTag[item.label as keyof typeof OrderableItemTag]
        )
    )
    .map((item) => {
      return (
        <NavigationLink key={item.label} asChild>
          <Link
            href={
              getTagUrl(
                OrderableItemTag[item.value as keyof typeof OrderableItemTag]
              ) as any
            }
          >
            <Tag
              tag={
                OrderableItemTag[item.value as keyof typeof OrderableItemTag]
              }
              active={false}
              size="sm"
              label={t(`dataset.Tags.${item.label}`)}
              className="w-full md:w-auto rounded-md border"
              defaultBackground="#fff"
            />
          </Link>
        </NavigationLink>
      );
    });
  return (
    <div
      className={twMerge(
        `grid grid-cols-1 md:flex md:flex-wrap md:items-center gap-2`,
        className
      )}
    >
      {status !== `idle` ? <Loading /> : Items}
    </div>
  );
};

export default ShopFlyoutMenuTags;
