/* This example requires Tailwind CSS v2.0+ */
"use client";
import { useMemo } from "react";
import { HiXMark } from "react-icons/hi2";
import { LuCalendarClock } from "react-icons/lu";

import * as PopoverPrimitive from "@radix-ui/react-popover";
import dynamic from "next/dynamic";
import { useTranslations } from "next-intl";
import { twMerge } from "tailwind-merge";

import Button from "@efarmz/efarmz-react-commons/esm/components/atoms/Button";

import { SubscriptionCartListSkeleton } from "@/components/organisms/SubscriptionCartList";

import useSubscription from "@/hooks/subscriptions/useSubscription";

const SubscriptionCartList = dynamic(
  () => import(`@/components/organisms/SubscriptionCartList`),
  { ssr: false, loading: () => <SubscriptionCartListSkeleton /> }
);

const HeaderSubscriptionInfo = () => {
  const t = useTranslations();
  const subscription = useSubscription();

  const count = useMemo(() => {
    let count = 0;

    subscription?.items?.forEach((orderItem) => {
      count = count + (orderItem.qty || 0);
    });

    subscription?.additionnalItems?.forEach((orderItem) => {
      count = count + (orderItem.qty || 0);
    });

    return count;
  }, [subscription]);

  /*
    <a href={`/${locale}/my/subscription`}>
      <div className="flex items-strech border rounded-md overflow-hidden">
        <div className="flex py-1 px-3 items-center">
          <svg
            className={twMerge(`h-1.5 w-1.5 mr-2  fill-green-500`)}
            viewBox="0 0 6 6"
            aria-hidden="true"
          >
            <circle cx={3} cy={3} r={3} />
          </svg>
          <span className="text-xs sm:text-sm font-medium inline-flex">
            Abonnement{` `}
          </span>
          <span className="ml-1 inline-flex items-centerjustify-center px-2 py-1 text-xs font-medium leading-none text-gray-100 bg-famille-500 rounded-full">
            {count}
          </span>
        </div>
        {currentSubscription.subscription.nextDeliveryDate && (
          <div className="text-xs sm:text-sm flex px-2 gap-1 items-center bg-menthe-100 text-vert-500 font-medium">
            <HiCalendar />
            {format(currentSubscription.subscription.nextDeliveryDate, `dd/MM`)}
          </div>
        )}
      </div>
    </a>
        */
  return (
    <div className="relative inline-block text-left">
      <PopoverPrimitive.Root>
        {subscription ? (
          <PopoverPrimitive.Trigger asChild>
            <Button
              className="bg-rouge-500 hover:bg-red-600 text-white relative"
              startIcon={<LuCalendarClock />}
            >
              {t(`common.subscription`)}
              {` `}
              {count > 0 && (
                <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-terreau-500 rounded-full">
                  {count}
                </span>
              )}
            </Button>
          </PopoverPrimitive.Trigger>
        ) : null}
        <PopoverPrimitive.Content
          align="center"
          sideOffset={4}
          className={twMerge(
            `radix-side-top:animate-slide-up radix-side-bottom:animate-slide-down`,
            `z-50 rounded-lg shadow-md md:w-[400px] overflow-hidden`,
            `bg-white`
          )}
        >
          <PopoverPrimitive.Arrow className="fill-current text-white" />

          <SubscriptionCartList />

          <PopoverPrimitive.Close
            className={twMerge(
              `absolute top-3.5 right-3.5 inline-flex items-center justify-center rounded-full p-1`,
              `focus:outline-none focus-visible:ring focus-visible:ring-vert-500 focus-visible:ring-opacity-75`
            )}
          >
            <HiXMark className="h-4 w-4 text-gray-500 hover:text-gray-700" />
          </PopoverPrimitive.Close>
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Root>
    </div>
  );
};

export default HeaderSubscriptionInfo;
