import React from "react";
import { HiOutlineTrash } from "react-icons/hi2";

import { useTranslations } from "next-intl";
import { twMerge } from "tailwind-merge";

import {
  Meal,
  OrderItem,
  OrderItemStatus,
  OrderType,
} from "@efarmz/efarmz-domain-typescript";
import Button from "@efarmz/efarmz-react-commons/esm/components/atoms/Button";
import useCurrency from "@efarmz/efarmz-react-commons/esm/hooks/useCurrency";

import Image from "@/components/atoms/Image";

import NumericInput from "@/components/forms/NumericInput";

import useAuth from "@/hooks/useAuth";

type MealOrderSummaryItemProps = {
  orderItem: Omit<OrderItem, `item`> & {
    item: Meal;
  };

  onUpdate?: (
    orderItem: Omit<OrderItem, `item`> & {
      item: Meal;
    },
    newPortion: number,
    orderType: OrderType
  ) => void;
  onRemove?: (
    orderItem: Omit<OrderItem, `item`> & {
      item: Meal;
    }
  ) => void;
  className?: string;
  orderType: OrderType;
  readonly?: boolean;
  showPrice?: boolean;
  showIcon?: boolean;
  Badge?: React.ReactNode;
  imageWrapperProps?: React.HTMLProps<HTMLDivElement>;
};

const MealOrderSummaryItem = ({
  orderItem,
  onUpdate,
  onRemove,
  className,
  orderType,
  readonly = false,
  showPrice = false,
  showIcon = true,
  Badge,
  imageWrapperProps = {},
}: MealOrderSummaryItemProps) => {
  const t = useTranslations();
  const { user } = useAuth();
  const currency = useCurrency();

  const { className: imageWrapperClassName, ...otherImageWrapperProps } =
    imageWrapperProps;

  const nameSplit = (orderItem?.item?.id && orderItem.item.id.split(`-`)) || [];
  const countPortion = parseInt(
    nameSplit[nameSplit.length - 1].replace(`P`, ``)
  );

  const handleUpdate = (qty: number, orderType: OrderType) => () => {
    const newPortion = countPortion + qty;

    const minPortion = 1;
    const maxPortion = 6;

    if (orderItem?.item && onUpdate && newPortion <= maxPortion) {
      onUpdate(orderItem, newPortion < minPortion ? 0 : newPortion, orderType);
    }
  };

  const handleRemove = () => {
    if (onRemove) {
      onRemove(orderItem);
    }
  };
  return (
    <div className={twMerge(`px-6 py-3`, className)}>
      <div className="flex items-start gap-2">
        {/*activeTab === OrderType.SUBSCRIPTION ? (
          <div className="flex flex-col justify-center h-full">
            <BsArrowReturnRight className="w-8" />
          </div>
        ) : null*/}
        <div className="overflow-hidden rounded-md self-start">
          <div
            className={twMerge(
              `h-20 w-full flex-shrink-0 relative`,
              readonly && `w-24 h-24`,
              imageWrapperClassName
            )}
          >
            <Image
              src={`https://cdn.efarmz.be/art/${orderItem?.item?.image}`}
              key={orderItem?.item?.image}
              fill
              alt=""
              sizes="200px"
              className="h-full w-full object-cover object-center absolute"
            />
          </div>
          {!readonly && !orderItem?.item?.id?.startsWith(`BOX`) && (
            <NumericInput
              size="sm"
              className="relative w-auto rounded-none"
              value={countPortion}
              onClickAdd={handleUpdate(1, OrderType.ONESHOT) as any}
              onClickRemove={handleUpdate(-1, OrderType.ONESHOT) as any}
            />
          )}
        </div>
        <div className="flex items-start flex-1">
          <div className="flex-1">
            <span className="block text-md text-vert-700 font-semibold max-w-md">
              {orderItem?.item?.name}
            </span>
            <span
              className={twMerge(
                `block mt-1 text-sm text-menthe-700 font-medium`
              )}
            >
              {t(`dataset.MealPortion`, { count: countPortion })}
            </span>
            {orderItem?.status === OrderItemStatus.AVAILABLE ||
            orderType === OrderType.SUBSCRIPTION ? (
              <div
                className={twMerge(
                  `flex justify-start mt-2 items-center gap-2`,
                  readonly && `mt-0`
                )}
              >
                <div>{Badge}</div>
              </div>
            ) : (
              <div className="flex flex-row justify-between">
                <p className="text-xs font-medium text-menthe-500 mt-1">
                  {t(`dataset.OrderItemStatus.${orderItem.status}`)}
                </p>
              </div>
            )}
          </div>
          {(onRemove || showPrice) && (
            <div className="flex items-center gap-1 md:gap-2">
              {showPrice && (
                <span className="bg-menthe-100 p-2 rounded-md text-xs md:text-sm text-vert-500 font-semibold">
                  {currency.format(
                    (orderItem?.qty || 1) *
                      ((orderType === OrderType.SUBSCRIPTION
                        ? orderItem?.item?.cataloguePrice
                        : orderItem?.item?.finalPrice) || 0)
                  )}
                </span>
              )}
              {onRemove && (
                <div>
                  <Button
                    className={twMerge(
                      `rounded-md flex items-center justify-center cursor-pointer bg-transparent text-menthe-600 p-2 bg-menthe-50`
                    )}
                    onClick={handleRemove}
                  >
                    <HiOutlineTrash />
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MealOrderSummaryItem;
