import React from "react";
import { LuMenu } from "react-icons/lu";

import dynamic from "next/dynamic";
import Image from "next/image";
import { useTranslations } from "next-intl";
import { twMerge } from "tailwind-merge";

import * as NavigationMenuPrimitive from "@/components/atoms/NavigationMenu";

import NavSidebar from "@/components/organisms/NavSidebar";

import { ShopFlyoutMenuSkeleton } from "@/components/layouts/Header/ShopFlyoutMenu";
import LogoWhite from "@/images/logo-white.svg";
import LogoWhiteSmall from "@/images/logo-white-small.svg";
import { Link } from "@/utils/navigation";

const ShopFlyoutMenu = dynamic(() => import(`./ShopFlyoutMenu`), {
  ssr: false,
  loading: () => <ShopFlyoutMenuSkeleton />,
});

export type ButtonProps = Omit<React.HTMLProps<HTMLButtonElement>, `size`> & {
  variant?: `contained` | `default`;
  color?: `primary` | `secondary` | `default`;
  type?: `button` | `submit` | `reset` | undefined;
  size?: `xs` | `sm` | `md` | `lg` | `xl`;
  loading?: boolean;
  startIcon?: React.ReactNode;
};

export type MenuItem = {
  label: string;
  href?: string;
  Menu?: any;
  className?: string;
};
type HeaderNavigationProps = {
  transparent?: boolean;
};

export const NavigationLink = NavigationMenuPrimitive.Link;

const HeaderNavigation = ({ transparent = false }: HeaderNavigationProps) => {
  const tKey = `components/Header`;
  const t = useTranslations(tKey);

  const links = [
    {
      label: t(`nav.box`),
      href: `/box`,
      Menu: null,
      className: `w-[21rem] lg:w-auto`,
    },
    {
      label: t(`nav.shop`),
      href: `/shop`,
      Menu: ShopFlyoutMenu,
      className: `w-[21rem] lg:w-auto`,
    },
    /*
    {
      label: t(`nav.brands`),
      href: `/brands`,
      Menu: null,
    },
    {
      label: t(`nav.values`),
      href: `/shop`,
      Menu: null,
    },
    */
  ];

  const Links = links?.length
    ? links.map((item, i) => {
        const linkClassName = twMerge(
          `hidden md:block px-2 py-2 text-sm rounded-md hover:bg-vert-900 hover:text-anis-500`,
          `text-md font-medium`,
          `text-menthe-100`,
          transparent && `text-white`,
          `focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75`
        );
        return (
          <NavigationMenuPrimitive.Item key={i.toString()} value={item.label}>
            {item?.Menu ? (
              <>
                <NavigationMenuPrimitive.Trigger>
                  <Link
                    href={item.href || (`` as any)}
                    className={linkClassName}
                  >
                    {item.label}
                  </Link>
                </NavigationMenuPrimitive.Trigger>

                <NavigationMenuPrimitive.Content
                  className={twMerge(
                    `absolute w-auto top-0 left-0 rounded-lg z-50`,
                    `radix-motion-from-start:animate-enter-from-left`,
                    `radix-motion-from-end:animate-enter-from-right`,
                    `radix-motion-to-start:animate-exit-to-left`,
                    `radix-motion-to-end:animate-exit-to-right`
                  )}
                >
                  <div
                    className={twMerge(
                      `max-w-[1280px] relative z-50`,
                      item?.className
                    )}
                  >
                    {<item.Menu />}
                  </div>
                </NavigationMenuPrimitive.Content>
              </>
            ) : (
              <Link href={item.href as any} className={linkClassName}>
                {item?.label}
              </Link>
            )}
          </NavigationMenuPrimitive.Item>
        );
      })
    : [];
  return (
    <>
      <NavigationMenuPrimitive.Root
        className="py-4 md:py-4 z-30"
        // value={"Notre shop"}
        //onValueChange={setValue}
      >
        <NavigationMenuPrimitive.List className="flex flex-row rounded-lg space-x-2 relative items-center">
          <li className="hidden md:block mr-6">
            <Link href={`/` as any}>
              <Image src={LogoWhite} className="w-20" alt="logo eFarmz" />
            </Link>
          </li>
          <li className="md:hidden flex justify-center items-center">
            <Link href={`/` as any} className="mr-3">
              <Image src={LogoWhiteSmall} className="w-6" alt="logo eFarmz" />
            </Link>
          </li>

          <li className="md:hidden flex items-center justify-center">
            <NavSidebar className="text-menthe-50">
              <LuMenu className="w-6 h-6" />
            </NavSidebar>
          </li>

          {Links}
          <NavigationMenuPrimitive.Indicator
            className={twMerge(
              `z-10`,
              `top-14 flex items-end justify-center h-2 overflow-hidden`,
              `radix-state-visible:animate-fade-in`,
              `radix-state-hidden:animate-fade-out`,
              `transition-[width_transform] duration-[250ms] ease-[ease]`
            )}
          >
            <div className="top-1 relative bg-white w-2 h-2 rotate-45" />
          </NavigationMenuPrimitive.Indicator>
        </NavigationMenuPrimitive.List>

        <div
          className={twMerge(
            `absolute flex justify-center z-50`,
            `w-[100%] left-0 right-0 top-[100%]`
          )}
          style={{
            perspective: `2000px`,
          }}
        >
          <NavigationMenuPrimitive.Viewport
            className={twMerge(
              `relative shadow-lg rounded-md bg-white overflow-hidden`,
              `w-radix-navigation-menu-viewport`,
              `h-radix-navigation-menu-viewport`,
              `radix-state-open:animate-scale-in-content`,
              `radix-state-closed:animate-scale-out-content`,
              `origin-[top_center] transition-[width_height] duration-300 ease-[ease]`
            )}
          />
        </div>
      </NavigationMenuPrimitive.Root>
    </>
  );
};

HeaderNavigation.displayName = `HeaderNavigation`;

export default HeaderNavigation;
