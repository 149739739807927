"use client";
import React, { Fragment, useState } from "react";
import { HiXMark } from "react-icons/hi2";

import { Dialog, Transition } from "@headlessui/react";
import dynamic from "next/dynamic";

import LogoWhite from "@/svg/LogoWhite";

const NavSidebarPanel = dynamic(() => import(`./MyNavSidebarPanel`), {
  ssr: false,
});

type NavSidebarProps = {
  children: React.ReactNode;
  className?: string;
};

const MyNavSidebar = ({ children, className }: NavSidebarProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button
        onClick={() => {
          setIsOpen(true);
        }}
        className={className}
      >
        {children}
      </button>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-terreau-900/80" />
          </Transition.Child>

          <div className="fixed flex inset-0">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setIsOpen(false)}
                      //aria-label={t(`trigger.aria-label`)}
                    >
                      <HiXMark
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-terreau-500 px-6 pb-4 relative">
                  <div className="flex h-16 shrink-0 items-center mt-4">
                    <LogoWhite className="w-24 sm:w-26 relative" />
                  </div>
                  <NavSidebarPanel onClose={() => setIsOpen(false)} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default MyNavSidebar;
